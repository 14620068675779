import React, { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../helper/firebaseClient";
import { useDepartment } from "../../stores/departmentStore";

export default function DepartmentLoader() {
  console.log(useDepartment());
  const setDepartments = useDepartment((state) => state.setDepartments);
  const listDepartments = useDepartment((state) => state.listDepartments);
  // listDepartments();
  console.log("Callse");
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listDepartments();
      }
    });

    return () => {
      setDepartments([]);
    };
  }, [auth.currentUser]);

  return <div></div>;
}
